import React, { useContext } from "react"
import { Link } from "gatsby"
import AppContext from "@src/context"
import { getLocalizedURL } from '@src/helper';

// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"

const Page404 = () => {

    const context = useContext(AppContext);
    const lang = context.currentLang;

    const content = {
        title: {
            en: 'Page Not Found | Lupkynis.com',
            es: 'Página no encontrada | Lupkynis.com',
        },
        h1: {
            en: 'HMMM...',
            es: 'Mmm...',
        },
        h2: {
            en: 'Sorry, this page does not exist',
            es: 'Lo sentimos, esta página no existe',
        },
        p: {
            en: "You may have an incorrect URL or you might have arrived at this page by mistake. Let's see if you can find what you're looking for back on the homepage.",
            es: 'Es posible que tenga una URL incorrecta o que haya llegado a esta página por error. Intente encontrar lo que busca en la página de inicio.',
        },
        link: {
            en: 'Return to home',
            es: 'Regresar al inicio',
        },
    }

    return (
        <Layout addedClass="page--error page--404">
            <Seo 
            title={content.title[lang]} 
            description="" 
            robots="noindex" 
            canonicalURL="https://www.lupkynis.com/404" 
            />
            <Hero addedClass="bg-light-green hero--string">
                <div className="col-wrapper">
                    <div className="container">
                        <div className="hero__content">
                            <h1 className="heading heading--accent">{content.h1[lang]}</h1>
                            <h2>{content.h2[lang]}</h2>
                            <p>{content.p[lang]}</p>
                            <Link to={getLocalizedURL('home', lang)} className="button">{content.link[lang]}</Link>
                        </div>
                        <div className="hero__image">
                            <Image data={{
                                desktopSrc: 'hero-warning.png', 
                                mobileSrc: 'hero-warning--mobile.png'
                            }} />
                        </div>
                    </div>
                </div>
            </Hero>
        </Layout>
    );
}

export default Page404